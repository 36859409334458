import React, {useEffect} from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import PanToolIcon from '@material-ui/icons/PanTool';
import PolicyIcon from '@material-ui/icons/Policy';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import __ from '../utils/i18n';
import {CLOUD} from '../api';
import {MOBILE_WIDTH} from '../utils/consts';

export default function BottomMenu(props) {
    const useStyles = makeStyles(theme => ({
        rootAction: {
            ...theme.bottomMenu[CLOUD]
        },
        rootButton: {
            ...theme.bottomMenuButton[CLOUD]
        },
        menuButton: {
            padding: '13px 5px',
            borderRadius: 0
        }
    }));

    const [width, setWidth] = React.useState(document.body.clientWidth);
    const updateWindowDimensions = () => setWidth(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();

        // returned function will be called on component unmount
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const classes = useStyles();

    return <ButtonGroup
        className={clsx(classes.rootAction)}
    >
        <Button
            startIcon={<ExitToAppIcon/>}
            component="a"
            href="/www/logout"
            className={classes.rootButton}
        >
            {__('Logout')}
        </Button>
        <Button style={{flexGrow: 1}} disabled={true}/>
        <Button
            startIcon={<PolicyIcon/>}
            component="a"
            title={width < MOBILE_WIDTH ? __('Imprint') : ''}
            href="/www/imprint"
            className={classes.rootButton}
        >
            {width >= MOBILE_WIDTH ? __('Imprint') : ''}
        </Button>
        <Button
            startIcon={<PanToolIcon/>}
            component="a"
            title={width < MOBILE_WIDTH ? __('Privacy') : ''}
            href="/www/policy"
            className={classes.rootButton}
        >
            {width >= MOBILE_WIDTH ? __('Privacy') : ''}
        </Button>
    </ButtonGroup>;
}
