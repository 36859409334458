import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ThemeProvider} from '@material-ui/styles';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

ReactDOM.render(
	<ThemeProvider theme={theme('colored')}>
		<App />
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (false && window.location.protocol === 'https:') {
	serviceWorkerRegistration.register();
} else {
	serviceWorkerRegistration.unregister();
}
reportWebVitals(console.log);
