const PORT = window.location.hostname.startsWith('iobroker.') ? window.location.port : 444;
const CLOUD = window.location.hostname === 'iobroker.net' ? 'net' : 'pro';
export {PORT};
export {CLOUD};

function _apiGetUrl(path) {
    const id = Date.now() + '_' + Math.round(Math.random() * 10000);
    if (window.location.hostname === 'localhost') {
        return `/${path}${path.includes('?') ? '&id=' + id : '?id=' + id}`;
    } else {
        return `https://iobroker.${CLOUD}:${PORT}/${path}${path.includes('?') ? '&id=' + id : '?id=' + id}`;
    }
}

function _apiLocalFetch(path, method, data, authNotRequired) {
    const options = {
        method: method || 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };

    if (!authNotRequired) {
        options.credentials = 'include';
    }

    if (method === 'POST' || method === 'DELETE' || method === 'PUT' || method === 'PATCH') {
        options.body = JSON.stringify(data);
    }

    return fetch(_apiGetUrl(path), options)
        .then(res => {
            if (res.status >= 400) {
                const status = res.status;
                return res.json()
                    .then(data => {
                        data = data || {};
                        data.statusCode = status;
                        return Promise.reject(data);
                    })
            } else {
                return res.json();
            }
        })
        .catch(e => {
            if (e && e.error) {
                return Promise.reject(e.error);
            }  else if (e && e.message) {
                return Promise.reject(e.message);
            } else {
                return Promise.reject(e);
            }
        });
}

export function apiGetIsConnected() {
    return _apiLocalFetch('checkConnection');
}
export function apiGetProgramsList() {
    return _apiLocalFetch('listOfPrograms');
}

