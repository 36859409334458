import React from 'react';
import clsx from 'clsx';

import {withStyles} from '@material-ui/styles';

import TopMenu from './Components/TopMenu';
import BottomMenu from './Components/BottomMenu';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress  from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import __, {getLang} from './utils/i18n';
import {apiGetIsConnected, apiGetProgramsList} from './api';
import {CLOUD} from './api';
import demoImg from './assets/img/demo.png';

const styles = theme => ({
	root: {
		display : 'flex',
		flexDirection:'row',
		width: '100%',
		height: 'calc(100% - 10px)',
		alignItems: 'flex-start'

	},
	root2: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems:'stretch',
		justifyContent: 'stretch',
	},
	root3: {
		display: 'flex',
		padding: 0
	},
	container: {
		height: '100%',
	},
	top: {
		height: 64
	},
	cont: {
		flexGrow: 1,
		height: 'calc(100% - 64px)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	content: {
		padding: 0,
		height: 'calc(100% - 41px)',
		overflowY: 'auto',
		display: 'flex',
		position: 'relative',
		flexDirection: 'row',
		flexWrap: 'wrap',
		...theme.content[CLOUD],
		alignItems: 'center',
		justifyContent: 'center',
	},
	card: {
		textAlign: 'center',
		width: 140,
		height: 200,
		margin: 20,
		animation: 'fadein 1s',
	},
	cardMedia: {
		height: 110,
	},
	cardActions: {
		display: 'block',
	},
	cardActionArea: {
		background: 'linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(220 218 218) 100%)',
	},
	cardButton: {
		marginTop: 3,
		//background: CLOUD === 'net' ? '#263238' : '#3499cc'
	},
	snackbarError: {
		background: '#ff9800',
	},
	status: {
		fontFamily: 'Arial',
		position: 'absolute',
		top: 10,
		left: 10,
	}
});

const CONNECT_INTERVAL = 10;
const READ_INTERVAL    = 10;

function CircularProgressWithLabel(props) {
	return [
		<div key="1" style={{flexGrow: 1, width: '100%'}}/>,
		<div key="2" style={{alignSelf: 'center'}}>
			<CircularProgress
				variant="determinate"
				color="primary"
				thickness={10}
				size={120}
				value={((CONNECT_INTERVAL - props.value) / CONNECT_INTERVAL) * 100} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Fab
					onClick={() => props.onClick && props.onClick()}
					title={__('Try to connect')}
					style={{fontSize: 24, marginTop: -4}}
				>{props.value && !props.connecting ? props.value : <SettingsInputAntennaIcon/>}</Fab>
			</Box>
		</div>,
		<div key="3" style={{flexGrow: 1, width: '100%'}}/>,
	]
}

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			connected: null,
			list: null,
			status: __('Wait...'),
			counter: CONNECT_INTERVAL,
			error: '',
			reading: false,
			firstAttempt: true,
			noLicenseFound: false,
		}

		this.interval = null;
	}

	componentDidMount() {
		this.pollConnectionStatus();
	}

	startPollInterval() {
		this.interval && clearInterval(this.interval);
		this.interval = setInterval(() => {
			if (this.state.counter > 0) {
				this.setState({counter: this.state.counter - 1, status: __(this.state.connected ? 'Reading...' : 'Wait...')});
			} else {
				this.interval && clearInterval(this.interval);
				this.interval = null;
				this.pollConnectionStatus();
			}
		}, 1000);
	}

	pollConnectionStatus() {
		this.interval && clearInterval(this.interval);
		this.interval = null;

		this.getConnected()
			.then(result => {
				if (result === 'error') {
					// do nothing, as it will be done the redirect
				} else
				if (!result) {
					this.setState({counter: CONNECT_INTERVAL, firstAttempt: false});
					this.startPollInterval();
				} else {
					return this.getListOfPrograms()
						.then(result => {
							if (!result) {
								this.setState({counter: READ_INTERVAL, firstAttempt: false});
								setTimeout(() => this.startPollInterval(), 100);
							}
						});
				}
			});
	}

	getConnected() {
		this.setState({connecting: true, status: __('Connecting...')});
		return apiGetIsConnected()
			.then(result => {
				this.setState({connecting: false, connected: !!result.result, error: __(result.error || '')});
				return !!result.result;
			})
			.catch(error => {
				if (error === 'not authenticated') {
					window.location = '/www/';
					return 'error';
				} else if (error === 'no app key found') {
					window.location = '/www/account/appkeys';
					return 'error';
				} else if (error === 'no license found') {
					this.setState({connecting: false, connected: false, error: __('No valid remote license found')});
					setTimeout(() => window.location = 'www/account/subscriptions', 4000);
					return 'error';
				} else {
					this.setState({connecting: false, connected: false, error: error && typeof error === 'object' ? __(error.error) || __(error) : __(error)});
					return false;
				}
			});
	}

	getListOfPrograms() {
		!this.state.reading && this.setState({reading: true});

		return apiGetProgramsList()
			.then(result => {
				if (result.list) {
					if (typeof result.list === 'string') {
						let list = result.list.replace(/\r\n|\n/g, ' ');
						const m = list.match(/list = (\[[^\]]+]);/);
						if (m) {
							try {
								let list = JSON.parse(m[1]);
								list.sort((a, b) => {
									const _a = a.order || 0;
									const _b = b.order || 0;
									if (_a > _b) {
										return -1;
									} else if (_a < _b) {
										return 1;
									} else {
										return 0;
									}
								});

								if (CLOUD === 'net') {
									list = list.filter(item => !item.pro);
								}

								// extract names
								const lang = getLang();
								list.forEach(item => {
									if (item.name && typeof item.name === 'object') {
										item.name = item.name[lang] || item.name.en || JSON.stringify(item.name);
									}
									if (item.instance !== undefined && item.instance !== null) {
										item.name += '.' + item.instance;
									}
									// lovelace is on port 4443
									if (item.name.startsWith('Lovelace') || item.link === 'lovelace/') {
										item.link = window.location.protocol + '//' + window.location.hostname + ':4443/lovelace/';
									}
								});

								this.setState({list, error: __(result.error || ''), reading: !list});
								return !!list;
							} catch (e) {
								this.setState({list: null, error: __('Cannot parse answer from your ioBroker')});
								return false;
							}
						} else {
							this.setState({list: null, error: __('Cannot parse answer from your ioBroker')});
							return false;
						}
					} else {
						this.setState({list: result.list, error: __(result.error || '')});
						return !!result.list;
					}
				} else {
					if (result && result.error) {
						this.setState({list: null, error: __(result.error)});
					} else {
						this.setState({list: null, error: __('Cannot get answer from your ioBroker: %s', result && result.error ? __(result.error) : JSON.stringify(result))});
					}
					return false;
				}
			})
			.catch(error => {
				if (error === 'not authenticated') {
					window.location = '/www/';
					return true;
				} else {
					this.setState({list: null, error: typeof error === 'object' ? __(error.error) || __(error) : __(error)});
					return false;
				}
			});
	}

	renderCounter() {
		if (this.state.firstAttempt || (this.state.connected && !this.state.reading)) {
			return null;
		} else {
			return [
				<CircularProgressWithLabel
					key="progress"
					connecting={this.state.connecting}
					onClick={() => this.pollConnectionStatus()}
					className={this.props.classes.counter}
					variant="determinate" value={this.state.counter} max={CONNECT_INTERVAL}
				/>,
				<div key="status" className={this.props.classes.status}>{this.state.status}</div>,
			];
		}
	}

	renderList() {
		if (this.state.list) {
			return this.state.list.map(item => this.renderCard(item));
		} else {
			return null;
		}
	}

	renderError() {
		if (this.state.error) {
			return <Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				ContentProps={{classes: {root: this.props.classes.snackbarError}}}
				open={true}
				autoHideDuration={3000}
				onClose={() => this.setState({error: ''})}
				message={this.state.error}
				action={
					<React.Fragment>
						<IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({error: ''})}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>;
		} else {
			return null;
		}
	}

	renderCard(item) {
		return <Card
			key={item.name}
			className={clsx(this.props.classes.card, 'card')}
			style={{background: item.color}}//`radial-gradient(circle, ${item.color} 20%, rgb(255 255 255) 100%)`}}
		>
			<CardActionArea className={this.props.classes.cardActionArea} onClick={() => window.location = item.link}>
				<CardContent>
					<img className={this.props.classes.cardMedia} src={item.img} alt={item.name} onError={() => {
						const pos = this.state.list.indexOf(item);
						const list = JSON.parse(JSON.stringify(this.state.list));
						list[pos].img = demoImg;
						this.setState({list});
					}}/>
				</CardContent>
			</CardActionArea>
			<CardActions className={this.props.classes.cardActions}>
				<Button
					className={this.props.classes.cardButton}
					size="small"
					color="primary"
					variant="contained"
					onClick={() => window.location = item.link}
				>{item.name}</Button>
			</CardActions>
		</Card>
	}

	render() {
		const classes = this.props.classes;

		if (this.state.connected === null) {
			return '...';
		}

		return <Container className={classes.root3}>
			<Card className={clsx(classes.root2, 'main')} raised={true}>
				<Grid container alignContent="center" direction="row" className={classes.container}>
					<Grid item xs={12} className={classes.top}>
						<TopMenu/>
					</Grid>
					<Grid item xs={12} className={classes.cont}>
						<div className={classes.content}>
							{this.renderCounter()}
							{this.renderList()}
						</div>
						<BottomMenu/>
					</Grid>
				</Grid>
			</Card>
			{this.renderError()}
		</Container>;
	}
}

export default withStyles(styles)(App);
