import React, {useEffect, Fragment} from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import MenuIcon from '@material-ui/icons/Menu';
import IconPerson from '@material-ui/icons/Person';

import {MOBILE_WIDTH, SMALL_MOBILE_WIDTH} from '../utils/consts';

import logoNet from '../assets/img/logo_net.svg';
import logoPro from '../assets/img/logo_pro.svg';
import logoNetSmall from '../assets/img/logo_net_small.svg';
import logoProSmall from '../assets/img/logo_pro_small.svg';
import __ from '../utils/i18n';
import {CLOUD} from '../api';

export default function TopMenu(props) {
    const useStyles = makeStyles(theme => ({
        root: {
            zIndex: 1201,
            ...theme.topMenu[CLOUD]
        },
        menu: {
            ...theme.mobileMenu[CLOUD]
        },
        title: {
            flexGrow: 1,
        },
        hamburger: {
            display: 'flex'
        },
        toolbar: {
            zIndex: 1201,
            padding: 0
        },
        mainButton: {
            //display: 'flex',
            alignItems: 'center'
        },
        avatar: {
            marginLeft: 10
        },
        avaMenuItem: {
            width: 200
        },
        avaButton: {
            color: theme.topMenu[CLOUD].color
        },
        menuButton: {
            height: 64,
            fontSize: '1rem',
        },
        mainMenuButton: {
            textAlign: 'center',
            color: theme.topMenu[CLOUD].color
        },
        mainMenuButtonActive: {
            textAlign: 'center',
            ...theme.topMenu[CLOUD].active
        },
        profileIcon: {
            color: CLOUD === 'net' ? '#000' : '#FFF',
            minWidth: 24,
        }
    }));

    const [width, setWidth] = React.useState(document.body.clientWidth);

    const updateWindowDimensions = () => setWidth(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();

        // returned function will be called on component unmount
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const logoHeight = 41;
    const logoWidth = width < SMALL_MOBILE_WIDTH ? 41 : 191;
    const classes = useStyles();

    /* mobile menu 	*/
    const [_anchorEl, _setAnchorEl] = React.useState(null);
    const _handleClick = event => _setAnchorEl(event.currentTarget);
    const _handleClose = () => _setAnchorEl(null);

    const switcher = [
        CLOUD === 'net' ?
            <Button
                key="net"
                component="span"
                className={clsx(classes.menuButton, classes.mainMenuButtonActive)}
                fullWidth={width < MOBILE_WIDTH}
            >
                <ListItemText primary="net"/>
            </Button> :
            <Button
                key="net"
                component="a"
                href={`https://iobroker.net/www/`}
                className={clsx(classes.menuButton, classes.mainMenuButton)}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary="net"/>
            </Button>
        ,
        CLOUD !== 'net' ?
            <Button
                key="pro"
                component="span"
                className={clsx(classes.menuButton, classes.mainMenuButtonActive)}
                fullWidth={width < MOBILE_WIDTH}
            >
                <ListItemText primary="pro"/>
            </Button> :
            <Button
                key="pro"
                component="a"
                href={`https://iobroker.pro/www/`}
                className={clsx(classes.menuButton, classes.mainMenuButton)}
                fullWidth={width < MOBILE_WIDTH}
                onClick={_handleClose}
            >
                <ListItemText primary="pro"/>
            </Button>
    ];

    const profileButton = <Button
        component="a"
        href="/www/account"
        key="account"
        title={width < SMALL_MOBILE_WIDTH ? __('Profile') : ''}
        className={clsx(classes.menuButton, classes.mainMenuButton)}
        fullWidth={width < MOBILE_WIDTH}
        onClick={_handleClose}
    >
        <ListItemIcon className={classes.profileIcon}><IconPerson/></ListItemIcon>
        {width >= SMALL_MOBILE_WIDTH ? <ListItemText primary={__('Profile')}/> : null}
    </Button>;

    const _menuMain = [
        <Button
            component="a"
            href="https://www.iobroker.net/"
            key="www"
            className={clsx(classes.menuButton, classes.mainMenuButton)}
            fullWidth={width < MOBILE_WIDTH}
        >
            <ListItemText primary="www"/>
        </Button>,
        <Button
            component="a"
            href="/www/pricing"
            key="prices"
            className={clsx(classes.menuButton, classes.mainMenuButton)}
            fullWidth={width < MOBILE_WIDTH}
            onClick={_handleClose}
        >
            <ListItemText primary={__('Pricing')}/>
        </Button>,
        width > MOBILE_WIDTH ? profileButton : null,
    ];

    const menuForm = width > MOBILE_WIDTH ?
        <Fragment>
            <Grid item>
                {switcher}
                {_menuMain}
            </Grid>
        </Fragment>
        :
        <Grid item className={classes.hamburger}>
            {width <= MOBILE_WIDTH ? profileButton : null}
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                onClick={_handleClick}
            >
                <MenuIcon/>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={_anchorEl}
                keepMounted
                open={Boolean(_anchorEl)}
                onClose={_handleClose}
                classes={{paper: classes.menu}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                transformOrigin={{vertical: 'top', horizontal: 'right',}}
            >
                {switcher}
                {_menuMain}
            </Menu>
        </Grid>;

    let logo;

    if (CLOUD === 'net') {
        logo = width < SMALL_MOBILE_WIDTH ? logoNetSmall : logoNet;
    } else {
        logo = width < SMALL_MOBILE_WIDTH ? logoProSmall : logoPro;
    }

    return <Grid container className={classes.root}>
        <Grid item className={classes.title}>
            <Button
                component="a"
                href="/www/"
                className={clsx(classes.menuButton, classes.mainButton)}
            >
                <img className={classes.logo} src={logo} height={logoHeight} width={logoWidth} alt="logo"/>
            </Button>
        </Grid>
        <Grid item style={{flexGrow: 1}}/>
        {menuForm}
    </Grid>;
}